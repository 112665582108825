import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import { Survey, Respondent, Group, StrataJobTitle, CompanyEvent, SurveyKey } from "../../types";
import { getLatestCompletedSurvey } from "../../hooks/helpers";
import Report from "./Report";

export default function Results() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const surveyIdPath = parseInt(location.pathname.replace("/assessments/", ""));
  const [surveyId, setSurveyId] = useState<number | null>(null);

  const { data: surveys, isFetched: surveysAreFetched } = useQuery<Survey[]>({
    queryKey: ["surveys", companyContext.companyId],
    queryFn: async () => getApiData(`/company/surveys`),
    initialData: [],
  });

  useEffect(() => {
    if (surveysAreFetched) {
      if (!isNaN(surveyIdPath)) {
        setSurveyId(surveyIdPath);
      } else {
        const latestCompletedSurvey = getLatestCompletedSurvey(surveys);
        if (latestCompletedSurvey) {
          setSurveyId(latestCompletedSurvey.survey_id ?? null);
        }
      }

      if (surveyId && !surveys.find((s) => s.survey_id === surveyId)) {
        navigate("/home");
      }
    }
  }, [surveys, surveysAreFetched, location]);

  const { error: surveyKeysError, data: surveyKeysWithGroups } = useQuery<SurveyKey[]>({
    queryKey: ["surveyKeyWithGroups", surveyId],
    queryFn: async () => getApiData(`/company/surveys/surveykeys/groups/${surveyId}`),
    enabled: !!surveyId,
    initialData: [],
  });

  // Get all respondents for a company no matter the survey hence why use company id
  const { data: respondents } = useQuery<Respondent[]>({
    queryKey: ["respondents", companyContext.companyId],
    queryFn: async () => getApiData(`/company/surveys/all/respondents-with-groups`),
    initialData: [],
  });

  // Get all groups with the min number of respondents for the assessment
  const { data: groups } = useQuery<Group[]>({
    queryKey: ["surveyGroups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/survey/${surveyId}`),
    enabled: !!surveyId,
    initialData: [],
  });

  // get strata job titles
  const { data: strataJobTitles } = useQuery<StrataJobTitle[]>({
    queryKey: ["strataJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/strata-job-titles`),
    initialData: [],
  });

  // get company_events
  const { data: companyEvents } = useQuery<CompanyEvent[]>({
    queryKey: ["companyEvents", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/events`),
    initialData: [],
  });

  if (!surveyId) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Report
          surveyId={surveyId}
          surveys={surveys}
          respondents={respondents}
          groups={groups}
          strataJobTitles={strataJobTitles}
          companyEvents={companyEvents}
          surveyKeysWithGroups={surveyKeysWithGroups}
        />
      </div>
    );
  }
}
