import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import { Group, Participant } from "../../types";
import { formatPhoneNumber, stripNonNumbers } from "../../hooks/helpers";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import IconButton from "../../components/IconButton";
import UpdateParticipantModal from "./UpdateParticipantModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import Button from "../../components/Button";
import { UserPlusIcon } from "@heroicons/react/16/solid";
import AssignParticipantGroupsModal from "./AssignParticipantGroupsModal";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import SearchInput from "./SearchInput";
import { useNotifications } from "../../contexts/notificationContext";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { ConfirmModalType, useConfirmationModal } from "../../contexts/confirmationModalContext";

function range(length: number) {
  return Array.from({ length }, (_, i) => i + 1);
}

export default function UserTable(props: { users: Participant[]; limit: number }) {
  const { users, limit } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userToUpdate, setUserToUpdate] = useState<Participant>();
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [checkedParticipants, setCheckedParticipants] = useState<Participant[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext);
  const { getApiData, deleteApiData } = useApi();
  const notifications = useNotifications();
  const confirm = useConfirmationModal();

  const { data: groups } = useQuery<Group[]>({
    queryKey: ["groups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/with-users`),
    initialData: [],
  });

  const filteredUsers = users.filter((user) => {
    if (!searchTerm) {
      return true;
    }

    return (
      user.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.phone_number && stripNonNumbers(user.phone_number).includes(searchTerm.toLowerCase())) ||
      user.company_job_title_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const totalPages = Math.ceil(filteredUsers.length / limit);
  const pages = range(totalPages);

  function paginate(users: Participant[], pageNumber: number) {
    const start = (pageNumber - 1) * limit;
    return users.slice(start, start + limit);
  }

  const currentPageUsers = paginate(filteredUsers, currentPage);

  if (totalPages >= 1 && currentPage > totalPages) {
    // user searched, results don't include current page
    setCurrentPage(1);
  }

  async function deleteUser(userId: number) {
    const confirmation = await confirm({
      title: "Remove Participant",
      message: "Are you sure you want to remove this participant?",
      confirmButtonText: "Remove",
      type: ConfirmModalType.danger
    });
    if (!confirmation) {
      return;
    }

    try {
      const { response, body } = await deleteApiData(`/company/users/${userId}`);

      if (response.status === 204) {
        notifications.addNotification("Success!", "Participant removed successfully", "success");
        queryClient.invalidateQueries({ queryKey: ["participants", companyContext.companyId] });
        queryClient.invalidateQueries({ queryKey: ["groups", companyContext.companyId] });
      }

      if (body.name === "TokenExpiredError") {
        window.location.reload();
      }

      notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
      console.error(body);
    } catch (err) {
      console.error(err);
    }
  }

  function exportUsers() {
    const headers = ["First Name", "Last Name", "Email", "Phone Number", "Job Title", "Group 1", "Group 2", "Group 3", "Group 4", "Group 5"];

    const rows = users.map((user) => [
      user.first_name,
      user.last_name,
      user.email,
      user.phone_number,
      user.company_job_title_name,
      groups.find((g) => g.group_id === user.group_ids[0])?.group_name,
      groups.find((g) => g.group_id === user.group_ids[1])?.group_name,
      groups.find((g) => g.group_id === user.group_ids[2])?.group_name,
      groups.find((g) => g.group_id === user.group_ids[3])?.group_name,
      groups.find((g) => g.group_id === user.group_ids[4])?.group_name,
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // create a link, click, then delete link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `Participants ${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    if (event.target.checked) {
      setCheckedParticipants([...checkedParticipants, value]);
    } else {
      setCheckedParticipants(checkedParticipants.filter((cp) => cp.user_id !== value.user_id));
    }
  };

  return (
    <>
      <UpdateParticipantModal participant={userToUpdate} allParticipants={users} open={openUserModal} setOpen={setOpenUserModal} />
      <AssignParticipantGroupsModal participants={checkedParticipants} setParticipants={setCheckedParticipants} open={openGroupModal} setOpen={setOpenGroupModal} />
      <div className="px-4 sm:px-6 lg:px-8 overflow-x-scroll sm:overflow-y-hidden">
        <div className="flex flex-col mt-4">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="">
                {users.length < 5 && (
                  <div className="rounded-md bg-yellow-50 p-4 mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                      </div>
                      <div className="ml-3">
                        <div className="text-sm text-yellow-700">
                          To view the results for this group, please ensure there are at least 5 participants to maintain anonymity
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex justify-between mb-4">
                  <SearchInput filter={searchTerm} setFilter={setSearchTerm} className="w-full sm:w-1/3" />

                  {checkedParticipants.length > 0 ? (
                    <div className="flex">
                      <div className="mr-3 text-sm text-gray-600 my-auto">{checkedParticipants.length} selected</div>
                      <Button text="Add to Group" variant="warning" icon={UserPlusIcon} onClick={() => setOpenGroupModal(true)} />
                    </div>
                  ) : (
                    <ArrowDownTrayIcon className="w-6 text-gray-600 hover:text-gray-800 mr-3 cursor-pointer" onClick={exportUsers} />
                  )}
                </div>
                <div className="border rounded-md">
                  <table className="min-w-full shadow-sm" style={{ borderSpacing: 0 }}>
                    <thead className="">
                      <tr className="z-10 border-b border-gray-300 h-10 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                        <th scope="col" className="w-10">
                          <input
                            type="checkbox"
                            checked={checkedParticipants.length > 0 && filteredUsers.length === checkedParticipants.length}
                            onChange={() => (filteredUsers.length === checkedParticipants.length ? setCheckedParticipants([]) : setCheckedParticipants(filteredUsers))}
                            className="ml-4 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                          />
                        </th>
                        <th className="pl-2">Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Job Title</th>
                        <th>Group(s)</th>
                        <th className="px-2"></th>
                        <th className="px-2"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {currentPageUsers.map((user, index: number) => (
                        <tr key={index} className="whitespace-nowrap h-10 pl-4 text-sm border-b border-gray-200">
                          <td className={""}>
                            <input
                              type="checkbox"
                              checked={checkedParticipants.find((cp) => cp.user_id === user.user_id) ? true : false}
                              onChange={(e) => handleCheckboxChange(e, user)}
                              aria-describedby="comments-description"
                              className="ml-4 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                            />
                          </td>
                          <td className={"text-gray-900 pl-2"}>
                            {user.first_name} {user.last_name}
                          </td>
                          <td className={"text-gray-900"}>{user.email}</td>
                          <td className={"text-gray-900"}>{user.phone_number && formatPhoneNumber(user.phone_number)}</td>
                          <td className={"text-gray-500"}>{user.company_job_title_name}</td>
                          <td className={"text-gray-500"}>
                            {user.group_ids
                              .map((id) => groups.find((group) => group.group_id === id))
                              .map((group) => group && group.group_name)
                              .join(", ")}
                          </td>
                          <td className={"text-gray-500"}>
                            <IconButton
                              icon={PencilIcon}
                              onClick={() => {
                                setUserToUpdate(user);
                                setOpenUserModal(true);
                              }}
                              className="h-5"
                            />
                          </td>
                          <td className={"text-red-400"}>
                            <IconButton
                              icon={TrashIcon}
                              onClick={() => {
                                deleteUser(user.user_id);
                              }}
                              className="h-5"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between py-3">
        <div className="flex flex-1 justify-between sm:hidden">
          {currentPage !== 1 && (
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-white"
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          {currentPage !== pages.length && (
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-white"
            >
              Next
            </button>
          )}
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            {filteredUsers.length > 0 ? (
              <p className="text-sm text-gray-700 ml-1">
                Showing <span className="font-medium">{((currentPage - 1) * limit) + 1}</span> to <span className="font-medium">{(currentPage * limit) > filteredUsers.length ? filteredUsers.length : currentPage * limit}</span> of{" "}
                <span className="font-medium">{filteredUsers.length}</span> users
              </p>
            ) : (
              <p className="text-sm text-gray-700 ml-1">No Results</p>
            )}
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`${
                  currentPage === 1 && "opacity-30"
                } relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20`}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {pages.map((page) => {
                let show = false;
                let isEllipsis = false;
                if (pages.length <= 10) {
                  // show all pages if under 10
                  show = true;
                } else if (page === 1 || page === totalPages) {
                  // always show first and last page
                  show = true;
                } else if (page <= currentPage + 3 && page >= currentPage - 3) {
                  // show pages next to current
                  show = true;
                } else if (page === currentPage + 4 || page === currentPage - 4) {
                  // show ellipsis for number in between ranges
                  show = true;
                  isEllipsis = true;
                }

                if (show) {
                  return (
                    <button
                      key={page}
                      aria-current="page"
                      onClick={() => setCurrentPage(page)}
                      className={
                        page === currentPage
                          ? "relative z-10 inline-flex items-center border border-blue-700 bg-blue-50 px-4 py-2 text-sm font-medium text-blue-800 focus:z-20"
                          : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20"
                      }
                      disabled={page === currentPage || isEllipsis}
                    >
                      {isEllipsis ? "..." : page}
                    </button>
                  );
                }
              })}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className={`${
                  currentPage === pages.length && "opacity-30"
                } relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-white focus:z-20`}
                disabled={currentPage === pages.length}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
