import Button from "../../components/Button";
import { useContext, useState } from "react";
import Spinner from "../../components/Spinner";
import Logo from "../../assets/blue.png";
import { Subscription } from "../../types";
import { capitalizeFirstLetter } from "../../hooks/helpers";
import Table from "../../components/Table";
import Payment from "../../components/Payment";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext, useCompany } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { PaymentMethod } from "@stripe/stripe-js";
import PaymentMethodModal from "./PaymentMethodModal";
import { StarIcon, TrashIcon } from "@heroicons/react/24/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import MeatballMenu from "../../components/MeatballMenu";
import { useNotifications } from "../../contexts/notificationContext";
import colors from "tailwindcss/colors";
import { ConfirmModalType, useConfirmationModal } from "../../contexts/confirmationModalContext";
import Alert from "../../components/Alert";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export default function ManagePayment() {
  const companyContext = useContext(CompanyContext);
  const { getApiData, postApiData, deleteApiData } = useApi();
  const { companyId } = useCompany();
  const notifications = useNotifications();
  const confirm = useConfirmationModal();
  const queryClient = useQueryClient();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);

  const { isPending: paymentIsPending, data: paymentInfo } = useQuery<Subscription>({
    queryKey: ["payment", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/info`),
    retry: false,
  });

  const { data: paymentMethods, isFetched: paymentMethodsAreFetched } = useQuery<{ paymentMethods: PaymentMethod[]; default: string }>({
    queryKey: ["paymentMethods", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/list-payment-methods`),
    initialData: { paymentMethods: [], default: "" },
    retry: false,
  });

  const { data: invoices } = useQuery<any[]>({
    queryKey: ["invoices", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/invoices`),
    initialData: [],
    retry: false,
  });

  async function setDefaultPaymentMethod(paymentMethodId: string) {
    await confirm({
      title: "Set Default Payment Method",
      message: "All future payments will be charged to this payment method",
      confirmButtonText: "Set as Default",
      onConfirm: async () => {
        const { response } = await postApiData(`/company/payment/set-default-payment-method/${paymentMethodId}`, {});

        if (response.ok) {
          notifications.addNotification("Success!", "Successfully set the default payment method");
          await queryClient.invalidateQueries({ queryKey: ["paymentMethods", companyId] });
        } else {
          notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
        }
      },
    });
  }

  async function deletePaymentMethod(paymentMethodId: string) {
    await confirm({
      title: "Delete Payment Method",
      message: "Are you sure you want to delete this payment method?",
      confirmButtonText: "Delete",
      type: ConfirmModalType.danger,
      onConfirm: async () => {
        const { response } = await deleteApiData(`/company/payment/payment-method/${paymentMethodId}`);

        if (response.ok) {
          notifications.addNotification("Success!", "The payment method was deleted successfully");
          await queryClient.invalidateQueries({ queryKey: ["paymentMethods", companyId] });
        } else {
          notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
        }
      },
    });
  }

  if (paymentIsPending) {
    return <Spinner />;
  }

  if (
    !paymentInfo?.stripe_subscription_id ||
    paymentInfo?.stripe_subscription_status === "incomplete" ||
    paymentInfo?.stripe_subscription_status === "incomplete_expired"
  ) {
    return (
      <div className="sm:flex">
        <div className="sm:w-1/3 sm:mr-5">
          <div className="bg-white rounded-lg shadow-md p-5 flex flex-col items-start w-full">
            <div className="flex items-center space-x-2">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img className="h-16 w-16 rounded-full" src={Logo} alt="" />
                  <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                </div>
              </div>
              <div className="text-xl font-semibold mt-2 text-gray-500">Strata Intel - Monthly Subscription</div>
            </div>
            <div className="ml-2 mt-5">
              {invoices.length > 0 && (
                <div className="text-gray-500 text-sm">
                  Amount Due: <span className="text-gray-700 font-semibold">${invoices[0].amount_due / 100}.00</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="sm:w-2/3">
          <div className="bg-white rounded-lg shadow-md p-5">
            <Payment reloadOnSuccess={true} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <PaymentMethodModal open={showPaymentMethodModal} setOpen={setShowPaymentMethodModal} paymentMethod={selectedPaymentMethod} />
      <div className="">
        <div>
          <div className="px-4 sm:px-0 border-b mb-4">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Subscription Information</h3>
            <p className="mt-1 mb-2 max-w-2xl text-sm leading-6 text-gray-500">Manage payment methods and view previous invoices</p>
          </div>
          <div className="md:flex md:items-center md:justify-between mt-2 bg-white px-5 py-3 rounded-lg shadow">
            <div className="flex space-x-5 items-center">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img className="h-16 w-16 rounded-full" src={Logo} alt="" />
                  <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                </div>
              </div>
              <div className="pt-1.5 h-full">
                <h2 className="text-2xl font-bold text-gray-900">Strata Intel - Monthly</h2>
                {invoices[0] && <h4 className="text-gray-700 text-sm">{formatter.format(invoices[0].amount_due / 100)} / month</h4>}
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
              <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-3 py-1 font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-3">
                {capitalizeFirstLetter(paymentInfo.stripe_subscription_status)}
              </span>
            </div>
          </div>
        </div>
        <div className="py-5">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Payment Methods</h3>
            </div>
            <div className="mt-2 flex-shrink-0">
              <Button
                text="New Payment Method"
                icon={PlusIcon}
                onClick={() => {
                  setSelectedPaymentMethod(null);
                  setShowPaymentMethodModal(true);
                }}
              />
            </div>
          </div>
          {paymentMethodsAreFetched && !paymentMethods.default && (
            <Alert
              title="No default payment method set"
              message="You do not have any payment methods set as your default for future payments. Please set a payment method as the default to avoid any potential disruptions of service"
            />
          )}
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-3">
            {paymentMethods.paymentMethods.length > 0 ? (
              paymentMethods.paymentMethods
                .sort((a, b) => (a.id === paymentMethods.default ? -1 : 1))
                .map((paymentMethod) => {
                  const isDefaultPaymentMethod = paymentMethod.id === paymentMethods.default;
                  return (
                    <li key={paymentMethod.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                      <div className="flex w-full items-center justify-between space-x-10 px-8 py-6">
                        <div className="flex-1">
                          <div className="flex items-center space-x-3">
                            <h3 className="text-sm font-medium text-gray-900">
                              {paymentMethod.card && `${capitalizeFirstLetter(paymentMethod.card.brand)}: ....${paymentMethod.card.last4}`}
                              {paymentMethod.us_bank_account &&
                                `${capitalizeFirstLetter(paymentMethod.us_bank_account.bank_name)}: ....${paymentMethod.us_bank_account.last4}`}
                            </h3>
                            {isDefaultPaymentMethod && (
                              <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Default
                              </span>
                            )}
                          </div>
                          <p className="mt-1 text-sm text-gray-500">
                            {paymentMethod.card &&
                              `${capitalizeFirstLetter(paymentMethod.card.funding)} | ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                            {paymentMethod.us_bank_account && `${capitalizeFirstLetter(paymentMethod.us_bank_account.account_type)}`}
                          </p>
                        </div>
                        <MeatballMenu
                          items={[
                            {
                              name: "Set as Default",
                              icon: StarIcon,
                              onClick: () => setDefaultPaymentMethod(paymentMethod.id),
                              hide: isDefaultPaymentMethod,
                            },
                            {
                              name: "Delete",
                              icon: TrashIcon,
                              onClick: () => deletePaymentMethod(paymentMethod.id),
                              color: colors.red[500],
                            },
                          ]}
                        />
                      </div>
                    </li>
                  );
                })
            ) : (
              <div className="text-xs text-gray-500">No payment methods found</div>
            )}
          </ul>
          <div className="py-5 mt-5">
            <div className="items-center justify-between">
              <div className="mt-2 mb-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Invoices</h3>
              </div>
              <Table
                items={invoices}
                structure={[
                  {
                    header: "Date",
                    column: "created",
                    customFormat: (date) => new Date((date as number) * 1000).toDateString(),
                  },
                  { header: "Total", column: "total", format: "cents" },
                  {
                    header: "Status",
                    column: "status",
                    customFormat: (text) => capitalizeFirstLetter(text as string),
                  },
                  {
                    header: "",
                    column: "hosted_invoice_url",
                    button: {
                      onClick: (value: string) => window.open(value, "_blank"),
                      text: "View Details",
                    },
                  },
                ]}
                limitPerPage={5}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
