import colors from "tailwindcss/colors";
import { Company, Survey } from "../../types";
import { calculateSum, getSIValueTextColorClassName } from "../../hooks/helpers";
import Tooltip from "../../components/Tooltip2";
import OverallDistribution from "./OverallDistribution";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid";

export default function OverviewHeader(props: { companies: Company[]; surveys: Survey[]; previousMonthSurveys: Survey[]; printable?: boolean }) {
  const { companies, surveys, previousMonthSurveys, printable } = props;

  const navigate = useNavigate();
  const { setCompanyId } = useContext(CompanyContext);

  const totalRespondents = calculateSum(surveys.map((s) => s.red_count! + s.yellow_count! + s.green_count! + s.disengaged_count!));
  const totalRecipients = calculateSum(surveys.map((s) => s.total_recipients));

  const data = [
    {
      value: calculateSum(surveys.map((s) => s.red_count!)),
      previousValue: calculateSum(previousMonthSurveys.map((s) => s.red_count!)),
      color: colors.red[500],
      text: "Individuals experiencing extreme stress and dysfunction, significantly impairing performance, well-being, and overall functioning.",
      title: "At-Risk",
    },
    {
      value: calculateSum(surveys.map((s) => s.yellow_count!)),
      previousValue: calculateSum(previousMonthSurveys.map((s) => s.yellow_count!)),
      color: colors.yellow[500],
      text: "Individuals experiencing higher than average levels of stress, unsustainable for long durations of time. Often associated with high levels of performance.",
      title: "Elevated",
    },
    {
      value: calculateSum(surveys.map((s) => s.green_count!)),
      previousValue: calculateSum(previousMonthSurveys.map((s) => s.green_count!)),
      color: colors.green[600],
      text: " Individuals with varying degrees of optimal engagement, performance, and manageable stress levels.",
      title: "Optimized",
    },
    {
      value: calculateSum(surveys.map((s) => s.disengaged_count!)),
      previousValue: calculateSum(previousMonthSurveys.map((s) => s.disengaged_count!)),
      color: colors.gray[500],
      text: "Individuals who are disengaged, not invested, or under reporting current stress levels.",
      title: "Disengaged",
    },
  ];

  return (
    <div className="md:flex justify-between gap-12">
      <div className="md:w-3/5 flex flex-col justify-between">
        <div className={`flex ${printable ? "flex-row justify-center" : "flex-col md:flex-row "} w-full h-full`}>
          <div className={`grid ${printable ? "grid-cols-6" : "grid-cols-1 md:grid-cols-6"} mb-5 gap-5 w-full`}>
            <div className="flex flex-col items-center">
              <div className={`flex justify-center items-center border border-gray-500 h-20 w-20 bg-white rounded-md`}>
                <h2 className={`${printable ? "mb-6" : ""} px-7 text-2xl font-bold`}>{totalRespondents}</h2>
              </div>
              <div className={`text-sm font-medium flex items-center gap-1 mt-2`}>Respondents</div>
            </div>
            <div className="flex flex-col items-center">
              <div className={`flex justify-center items-center border border-gray-500 h-20 w-20 bg-white rounded-md`}>
                <h2 className={`${printable ? "mb-6" : ""} px-7 text-2xl font-bold`}>{((totalRespondents / totalRecipients) * 100).toFixed(0)}%</h2>
              </div>
              <div className={`text-sm font-medium flex items-center gap-1 mt-2`}>Response Rate</div>
            </div>
            {data.map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <div
                  className={`flex justify-center items-center border h-20 w-20 bg-white rounded-md`}
                  style={{
                    borderColor: item.color,
                  }}
                >
                  <h2
                    className={`${printable ? "mb-6" : ""} px-7 text-2xl font-bold`}
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.value}
                  </h2>
                </div>
                <div className={`text-sm font-medium flex items-center gap-1 mt-2`} style={{ color: item.color }}>
                  {item.title} <Tooltip message={item.text} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="h-20 -ml-2 -mt-1 p-0">
            <OverallDistribution red={data[0].value} yellow={data[1].value} green={data[2].value} disengaged={data[3].value} />
          </div>
        </div>
      </div>
      <div className="md:w-2/5">
        <div className="inine-block min-w-full align-middle rounded-lg shadow overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg h-full">
          <table className="min-w-full divide-y divide-gray-300 h-full">
            <thead className="bg-gray-50 rounded-lg">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-gray-900">
                  SI Value
                </th>
                <th scope="col" className="px-3 pl-3 text-left text-sm font-medium text-gray-900">
                  Response Rate
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white w-full h-full">
              {companies
                .sort((a, b) => {
                  const surveyA = surveys.find((s) => s.company_id === a.company_id);
                  const surveyB = surveys.find((s) => s.company_id === b.company_id);
                  if (!surveyA || surveyA.si_value === null) return -1;
                  if (!surveyB || surveyB?.si_value === null) return 1;
                  return parseFloat(surveyB.si_value) - parseFloat(surveyA.si_value);
                })
                .map((company, index) => {
                  const survey = surveys.find((s) => s.company_id === company.company_id);
                  const previousSurvey = previousMonthSurveys.find((s) => s.company_id === company.company_id);
                  const siValueChange = survey && previousSurvey ? parseFloat(survey.si_value!) - parseFloat(previousSurvey.si_value!) : null;
                  const responseRateChange = survey && previousSurvey ? parseFloat(survey.response_rate!) - parseFloat(previousSurvey.response_rate!) : null;
                  return (
                    <tr key={index} className="">
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-6">
                        {survey ? (
                          <div
                            onClick={() => {
                              setCompanyId(company.company_id);
                              navigate(`/assessments/${survey.survey_id}`);
                            }}
                            className="underline cursor-pointer hover:text-gray-900"
                          >
                            {company.company_name}
                          </div>
                        ) : (
                          <div>{company.company_name}</div>
                        )}
                      </td>
                      <td className={`whitespace-nowrap px-3 py-2 ${survey && getSIValueTextColorClassName(survey.si_value!)} font-semibold`}>
                        {survey ? (
                          <div className="flex items-center">
                            <div>{parseFloat(survey.si_value!).toFixed(0)}</div>
                            {siValueChange !== null && (
                              <div className="ml-2 text-xs">
                                {siValueChange > 0 ? (
                                  <div className={`flex items-center text-green-500`}>
                                    <ArrowUpIcon className="h-4 w-4 shrink-0 self-center" />
                                    <div>{siValueChange.toFixed(0)}</div>
                                  </div>
                                ) : siValueChange < 0 ? (
                                  <div className={`flex items-center text-red-500`}>
                                    <ArrowDownIcon className="h-4 w-4 shrink-0 self-center" />
                                    <div className="font-medium">{siValueChange.toFixed(0)}</div>
                                  </div>
                                ) : (
                                  <div className="text-sm text-gray-400 font-medium">-</div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="text-sm text-gray-400 font-medium">N/A</div>
                        )}
                      </td>
                      <td className="whitespace-nowrap pl-3 pr-6 py-2 text-sm text-gray-500 w-1/4">
                        {survey ? (
                          <div className="flex items-center">
                            <div>{parseFloat(survey.response_rate!).toFixed(0)}%</div>
                            {responseRateChange !== null && (
                              <div className="ml-2 text-xs">
                                {responseRateChange > 0 ? (
                                  <div className={`flex items-center text-green-500`}>
                                    <ArrowUpIcon className="h-4 w-4 shrink-0 self-center" />
                                    <div>{responseRateChange.toFixed(0)}%</div>
                                  </div>
                                ) : responseRateChange < 0 ? (
                                  <div className={`flex items-center text-red-500`}>
                                    <ArrowDownIcon className="h-4 w-4 shrink-0 self-center" />
                                    <div>{responseRateChange.toFixed(0)}%</div>
                                  </div>
                                ) : (
                                  <div className="text-sm text-gray-400 font-medium">-</div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="text-sm text-gray-400 font-medium">N/A</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
