import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Respondent, Survey, Group, StrataJobTitle, CompanyEvent, SurveyKey } from "../../types";
import RespondentDistribution from "./RespondentDistribution";
import RespondentCategory from "./RespondentCategory";
import Container from "../../components/Container";
import TrendChart from "../../components/TrendChart";
import GroupCategoryComparison from "./GroupCategoryComparison";
import Divider from "../../components/Divider";
import { ChevronDownIcon, LockClosedIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { displayMonth, getInitialsFromName, getLatestCompletedSurvey } from "../../hooks/helpers";
import Dropdown from "../../components/Dropdown";
import ReportHelp from "./ReportHelp";
import { ArrowDownTrayIcon, CheckIcon, ListBulletIcon, QueueListIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../components/Tooltip";
import {
  calculateGroupImpairedTrendDatasets,
  calculateLatestCompletedGroupCounts,
  calculateLatestCompletedStrataJobTitleCounts,
  calculateStrataJobTitleImpairedTrendDatasets,
  calculateStressCategoryDatasets,
} from "./helpers";
import Checkbox from "./Checkbox";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { useAuth } from "../../contexts/authContext";
import { CompanyContext } from "../../contexts/companyContext";
import GroupParticipationRates from "./GroupParticipationRates";

export default function Report(props: { surveyId: number; surveys: Survey[]; respondents: Respondent[]; groups: Group[]; strataJobTitles: StrataJobTitle[]; companyEvents?: CompanyEvent[]; surveyKeysWithGroups: any }) {
  const { surveyId, surveys, respondents, groups, strataJobTitles, companyEvents, surveyKeysWithGroups } = props;

  const navigate = useNavigate();
  const auth = useAuth();
  const { companyId } = useContext(CompanyContext);

  const [selectedGroupFilters, setSelectedGroupFilters] = useState<number[]>([]);
  const [selectedStrataJobTitleFilters, setSelectedStrataJobTitleFilters] = useState<number[]>([]);
  const [showCustomGroups, setShowCustomGroups] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  async function getPDFResults() {
    setLoadingPDF(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/company/surveys/pdf/${surveyId}`, {
      method: "GET",
      headers: {
        Authorization: auth?.sessionInfo?.idToken ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
      },
    });

    const arrayBuffer = await response.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const blob = new Blob([uint8Array], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);

    // open the PDF in a new tab
    window.open(blobUrl, "_blank");
    setLoadingPDF(false);
  }

  const survey = surveys.find((s) => s.survey_id === surveyId);
  const latestRespondents = respondents.filter((r) => r.survey_id === surveyId);
  const completedSurveys = surveys.filter((s) => new Date(s.end_date) < new Date());

  // calculate datasets for stress category by Group
  const stressCategoryDatasets = calculateStressCategoryDatasets(completedSurveys, respondents, showCustomGroups, selectedStrataJobTitleFilters, selectedGroupFilters);

  // broken down by strata job titles and groups (grouped bar)
  const latestCompletedStrataJobTitleCounts = calculateLatestCompletedStrataJobTitleCounts(strataJobTitles, latestRespondents);
  const latestCompletedGroupCounts = calculateLatestCompletedGroupCounts(groups, latestRespondents);

  // strata job title and groups impaired
  const strataJobTitleImpairedTrendDatasets = calculateStrataJobTitleImpairedTrendDatasets(strataJobTitles, completedSurveys, respondents);
  const groupImpairedTrendDatasets = calculateGroupImpairedTrendDatasets(groups, completedSurveys, respondents);

  const groupDropdownOptions = ["Custom Groups", "Clinical Groups"];

  function calculateParticipationRates(surveyKeysWithGroup: any, showCustom: boolean) {
    // Create an object to store counts for each group
    const groupStats = surveyKeysWithGroup.reduce(
      (acc: { [x: string]: { completed: number; total: number } }, surveyKeyGroup: { group_name: string; is_taken: boolean; strata_job_title_name: string }) => {
        let group = showCustom ? surveyKeyGroup.group_name : surveyKeyGroup.strata_job_title_name;
        if (!group) {
          group = "Not Assigned";
        }
        if (!acc[group]) {
          acc[group] = {
            total: 0,
            completed: 0,
          };
        }
        acc[group].total += 1;
        if (surveyKeyGroup.is_taken) {
          acc[group].completed += 1;
        }
        return acc;
      },
      {}
    );

    // Calculate percentages for each group
    const results = Object.entries(groupStats).map(([group, stats]: any[]) => ({
      group,
      participationRate: ((stats.completed / stats.total) * 100).toFixed(0),
    }));

    return results;
  }

  let selectedParticipationCalculaiton = showCustomGroups ? calculateParticipationRates(surveyKeysWithGroups, true) : calculateParticipationRates(surveyKeysWithGroups, false);

  if (!surveyId || !survey) {
    return (
      <div>
        <div className="">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Mental Health Breakdown</h2>
        </div>
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col justify-center content-center items-center">
            <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
            <h1 className="text-xl font-bold">No Breakdown Results - Yet</h1>
            <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="report" className="">
        <div className="mb-6 flex justify-between">
          {completedSurveys.length > 1 ? (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-center items-center gap-x-1 text-xl sm:text-2xl font-bold tracking-tight text-gray-900 px-2 py-1 rounded-md hover:bg-stone-200">
                  {displayMonth(new Date(survey.start_date).getMonth())} {new Date(survey.start_date).getFullYear()} Results
                  <ChevronDownIcon aria-hidden="true" className="-mr-1 h-7 w-7 text-gray-700" />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {completedSurveys
                  .sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime())
                  .map((s, index) => (
                    <div key={index} className="">
                      <MenuItem>
                        <div
                          className={`flex items-center px-4 py-2 ${s.survey_id === survey.survey_id ? "bg-gray-100 cursor-default" : "hover:bg-gray-100 cursor-pointer"}`}
                          onClick={() => navigate(`/assessments/${s.survey_id}`)}
                        >
                          <div className={`block text-sm text-gray-700`}>
                            {displayMonth(new Date(s.start_date).getMonth())} {new Date(s.start_date).getFullYear()}
                          </div>
                          {s.survey_id === survey.survey_id && <CheckIcon className="size-4 text-gray-400 ml-1" />}
                        </div>
                      </MenuItem>
                    </div>
                  ))}
              </MenuItems>
            </Menu>
          ) : (
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
              {displayMonth(new Date(survey.start_date).getMonth())} {new Date(survey.start_date).getFullYear()} Results
            </h2>
          )}
          <div className="flex gap-3">
            {loadingPDF ? (
              <svg className="h-5 w-5 animate-spin text-gray-600 mt-1" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <Tooltip message="Download a PDF version of this report" position="below">
                <ArrowDownTrayIcon className="w-6 h-6 text-gray-600 hover:text-gray-900 cursor-pointer" onClick={getPDFResults} />
              </Tooltip>
            )}
          </div>
        </div>
        <RespondentCategory
          responseRate={survey.response_rate ? parseFloat(survey.response_rate) : 0}
          green={survey.green_count ?? 0}
          yellow={survey.yellow_count ?? 0}
          red={survey.red_count ?? 0}
          gray={survey.disengaged_count ?? 0}
          totalRecipients={survey.total_recipients}
        />

        <ReportHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />

        <div className="mt-8 mb-8">
          <Divider heading={""} />
        </div>

        <div className="flex items-center mb-4">
          <div className="mr-4">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">How is Your Team Experiencing Stress</h2>
          </div>
          <div className="relative group">
            <QuestionMarkCircleIcon className="h-6 w-6 cursor-pointer" onClick={() => setOpenHelp(!openHelp)} />

            <div className="absolute right-1/2 transform translate-x-1/2 -top-[70px] hidden w-48 p-3 text-sm text-white bg-black rounded-lg group-hover:block">
              Learn more about what this measures
              <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
            </div>
          </div>
        </div>

        <Container title="" className="col-span-3 h-96 mb-4">
          <RespondentDistribution respondents={latestRespondents} />
        </Container>

        <div className="flex mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl mr-4">Group Breakdown</h2>
          {latestCompletedGroupCounts.length > 0 && (
            <div className="">
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
              />
            </div>
          )}
        </div>
        <div className="grid grid-cols-5 gap-4">
          <Container title="Participation Rates" className="lg:col-span-1 col-span-5 w-full">
            <GroupParticipationRates selectedParticipationCalculaiton={selectedParticipationCalculaiton} />
          </Container>

          <Container title="" className="relative lg:col-span-4 col-span-5 h-96 w-full">
            <GroupCategoryComparison groupCounts={showCustomGroups ? latestCompletedGroupCounts : latestCompletedStrataJobTitleCounts} />
          </Container>
        </div>

        <div className="mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Stress Trend Over Time</h2>
        </div>

        <div className="mb-6 grid grid-cols-5 gap-4">
          <Container className={`lg:col-span-4 col-span-5 w-full h-96 relative`}>
            <TrendChart
              datasets={stressCategoryDatasets}
              stepSize={1}
              suggestedMin={0}
              suggestedMax={5}
              calcType="add"
              yAxisUnits="Respondents"
              showPercentageToggle={true}
              companyEvents={companyEvents}
            />
          </Container>

          <div className="w-full h-full flex flex-col col-span-5 lg:col-span-1">
            {latestCompletedGroupCounts.length > 0 && (
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-2 z-20  "
              />
            )}

            <Container title="Filter" className="flex-1 w-full">
              <div className="h-full overflow-scroll pl-1 mt-4">
                {showCustomGroups
                  ? groups.map((group) => (
                      <Checkbox
                        key={group.group_id}
                        id={group.group_id}
                        name={group.group_name}
                        checked={!!selectedGroupFilters.find((filter) => filter === group.group_id)}
                        setChecked={(checked: boolean) => {
                          if (checked) {
                            setSelectedGroupFilters((prevSelected) => [...prevSelected, group.group_id]);
                          } else {
                            setSelectedGroupFilters((prevSelected) => prevSelected.filter((item) => item !== group.group_id));
                          }
                        }}
                      />
                    ))
                  : strataJobTitles.map((strataJobTitle) => (
                      <Checkbox
                        key={strataJobTitle.strata_job_title_id}
                        id={strataJobTitle.strata_job_title_id}
                        name={strataJobTitle.strata_job_title_name}
                        checked={!!selectedStrataJobTitleFilters.find((filter) => filter === strataJobTitle.strata_job_title_id)}
                        setChecked={(checked: boolean) => {
                          if (checked) {
                            setSelectedStrataJobTitleFilters((prevSelected) => [...prevSelected, strataJobTitle.strata_job_title_id]);
                          } else {
                            setSelectedStrataJobTitleFilters((prevSelected) => prevSelected.filter((item) => item !== strataJobTitle.strata_job_title_id));
                          }
                        }}
                      />
                    ))}
              </div>
            </Container>
          </div>
        </div>

        <div>
          <div className="flex mb-4 mt-12">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl mr-4">Elevated Stress (Red and Yellow) by Group</h2>
            {latestCompletedGroupCounts.length > 0 && (
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="z-20 flex"
              />
            )}
          </div>
          <Container className="lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative">
            <TrendChart
              datasets={showCustomGroups ? groupImpairedTrendDatasets : strataJobTitleImpairedTrendDatasets}
              suggestedMax={5}
              suggestedMin={0}
              calcType="add"
              yAxisUnits="Respondents"
              showPercentageToggle={true}
              companyEvents={companyEvents}
            />
          </Container>
        </div>

        <div>
          <div className="mb-4 mt-12">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Response Rate Over Time</h2>
          </div>
          <Container className="lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative">
            <TrendChart
              datasets={[
                {
                  label: "Response Rate",
                  data: completedSurveys.map((s) => ({
                    value: parseFloat(s.response_rate ?? "0") / 100,
                    date: s.start_date,
                  })),
                },
              ]}
              // stepSize={10}
              suggestedMax={100}
              suggestedMin={0}
              calcType="average"
              displayType="percent"
              yAxisLabel="Response Rate"
              companyEvents={companyEvents}
              hideLegend={true}
            />
          </Container>
        </div>
      </div>
    );
  }
}
