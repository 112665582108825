import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Respondent, Survey, Group, StrataJobTitle, CompanyEvent, Company, Dataset } from "../../types";
import RespondentDistribution from "../Report/RespondentDistribution";
import RespondentCategory from "../Report/RespondentCategory";
import Container from "../../components/Container";
import TrendChart from "../../components/TrendChart";
import GroupCategoryComparison from "../Report/GroupCategoryComparison";
import Divider from "../../components/Divider";
import { ChevronDownIcon, LockClosedIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { calculateAverage, calculateSum, displayMonth, getInitialsFromName, getLatestCompletedSurvey } from "../../hooks/helpers";
import Dropdown from "../../components/Dropdown";
import ReportHelp from "../Report/ReportHelp";
import { ArrowDownTrayIcon, CheckIcon, ListBulletIcon, QueueListIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../components/Tooltip";
import {
  calculateGroupImpairedTrendDatasets,
  calculateLatestCompletedGroupCounts,
  calculateLatestCompletedStrataJobTitleCounts,
  calculateStrataJobTitleImpairedTrendDatasets,
  calculateStressCategoryDatasets,
} from "../Report/helpers";
import Checkbox from "../Report/Checkbox";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import colors from "tailwindcss/colors";
import CompanyDistributions from "./CompanyDistributions";
import OverviewHeader from "./OverviewHeader";
import { CompanyContext } from "../../contexts/companyContext";

export default function OverviewReport(props: { surveys: Survey[]; respondents: Respondent[]; companies: Company[]; companyEvents?: CompanyEvent[] }) {
  const { surveys, respondents, companies, companyEvents } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { setShowAllAccounts } = useContext(CompanyContext);

  const [selectedMonth, setSelectedMonth] = useState<{ month: number; year: number }>();
  const [selectedCompanyFilters, setSelectedCompanyFilters] = useState<number[]>([]);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const surveyIdPath = parseInt(location.pathname.replace("/assessments/", ""));

  let surveyId: number | null = null;
  if (!isNaN(surveyIdPath)) {
    surveyId = surveyIdPath;
  }

  if (!surveyId) {
    const latestCompletedSurvey = getLatestCompletedSurvey(surveys);

    if (latestCompletedSurvey) {
      surveyId = latestCompletedSurvey.survey_id ?? null;
    }
  } else if (!surveys?.find((s) => s.survey_id === surveyId)) {
    navigate("/home");
  }

  const completedSurveys = surveys.filter((s) => new Date(s.end_date) < new Date());

  const surveysForSelectedMonth = completedSurveys.filter(
    (s) => new Date(s.start_date).getMonth() + 1 === selectedMonth?.month && new Date(s.start_date).getFullYear() === selectedMonth.year
  );

  const previousMonth = selectedMonth
    ? selectedMonth.month > 1
      ? { month: selectedMonth?.month - 1, year: selectedMonth.year }
      : { month: 12, year: selectedMonth.year - 1 }
    : null;
  const surveysForPreviousMonth = completedSurveys.filter(
    (s) => new Date(s.start_date).getMonth() + 1 === previousMonth?.month && new Date(s.start_date).getFullYear() === previousMonth.year
  );
  const respondentsForSelectedMonth = respondents.filter((r) => surveysForSelectedMonth.map((s) => s.survey_id).includes(r.survey_id));

  const monthsWithCompletedSurveys = Array.from(
    new Map(
      completedSurveys.map((s) => {
        const month = new Date(s.start_date).getMonth() + 1;
        const year = new Date(s.start_date).getFullYear();
        return [`${month}-${year}`, { month, year }];
      })
    ).values()
  ).sort((a, b) => b.year - a.year || b.month - a.month);

  useEffect(() => {
    setSelectedMonth(monthsWithCompletedSurveys[0]);
  }, [surveys]);

  const stressCategoryDatasets: Dataset[] = [
    {
      label: "At-risk",
      data: completedSurveys
        .filter((s) => selectedCompanyFilters.length === 0 || selectedCompanyFilters.includes(s.company_id))
        .map((s) => ({ value: s.red_count!, date: s.start_date })),
      color: colors.red[400],
    },
    {
      label: "Impaired",
      data: completedSurveys
        .filter((s) => selectedCompanyFilters.length === 0 || selectedCompanyFilters.includes(s.company_id))
        .map((s) => ({ value: s.yellow_count!, date: s.start_date })),
      color: colors.yellow[400],
    },
    {
      label: "Optimized",
      data: completedSurveys
        .filter((s) => selectedCompanyFilters.length === 0 || selectedCompanyFilters.includes(s.company_id))
        .map((s) => ({ value: s.green_count!, date: s.start_date })),
      color: colors.green[400],
    },
    {
      label: "Disengaged",
      data: completedSurveys
        .filter((s) => selectedCompanyFilters.length === 0 || selectedCompanyFilters.includes(s.company_id))
        .map((s) => ({ value: s.disengaged_count!, date: s.start_date })),
      color: colors.gray[400],
    },
  ];

  useEffect(() => {
    setShowAllAccounts(true); //ensure that they are on all accounts tab
  }, []);
  return (
    <div id="report" className="">
      <div className="mb-6">
        {selectedMonth && (
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Account Results Overview</h2>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">An aggregate report of all your accounts</p>
            </div>

            <div className="flex items-center gap-4">
              <Menu as="div" className="relative inline-block text-left border-2 bg-white rounded-lg hover:bg-stone-50">
                <div className="">
                  <MenuButton className="inline-flex w-full justify-center items-center gap-x-2 text-xl sm:text-2xl font-bold tracking-tight text-gray-900 px-2 py-1 rounded-md">
                    {displayMonth(selectedMonth.month - 1)} {selectedMonth.year}
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  {monthsWithCompletedSurveys.map((d, index) => (
                    <div onClick={() => setSelectedMonth(d)} key={index}>
                      <MenuItem>
                        <div
                          className={`flex items-center px-4 py-2 ${selectedMonth.month === d.month && selectedMonth.year === d.year ? "bg-gray-100 cursor-default" : "hover:bg-gray-100 cursor-pointer"}`}
                        >
                          <div className={`block text-sm text-gray-700`}>
                            {displayMonth(d.month - 1)} {d.year}
                          </div>
                          {selectedMonth.month === d.month && selectedMonth.year === d.year && <CheckIcon className="size-4 text-gray-400 ml-1" />}
                        </div>
                      </MenuItem>
                    </div>
                  ))}
                </MenuItems>
              </Menu>
            </div>
          </div>
        )}
      </div>

      <div className="border mb-12"></div>

      <OverviewHeader companies={companies} surveys={surveysForSelectedMonth} previousMonthSurveys={surveysForPreviousMonth} />

      <ReportHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />

      <div className="mb-4 mt-12">
        <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Account Distributions</h2>
      </div>
      <Container className="col-span-3 h-96 mb-4">
        <CompanyDistributions companies={companies} surveys={surveysForSelectedMonth} />
      </Container>

      <div className="mb-4 mt-12">
        <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">SI Value Over Time</h2>
      </div>
      <Container className={`w-full h-96 relative`}>
        <TrendChart
          datasets={companies.map((c) => ({
            label: c.company_name,
            data: completedSurveys
              .filter((s) => s.company_id === c.company_id)
              .map((s) => ({
                value: parseFloat(s.si_value ?? "0"),
                date: s.start_date,
              })),
          }))}
          suggestedMax={100}
          suggestedMin={0}
          stepSize={10}
          calcType="average"
          yAxisLabel="SI Value"
          companyEvents={companyEvents}
        />
      </Container>

      <div className="mb-4 mt-12">
        <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Response Rate Over Time</h2>
      </div>
      <Container className={`w-full h-96 relative`}>
        <TrendChart
          datasets={companies.map((c) => ({
            label: c.company_name,
            data: completedSurveys
              .filter((s) => s.company_id === c.company_id)
              .map((s) => ({
                value: parseFloat(s.response_rate ?? "0") / 100,
                date: s.start_date,
              })),
          }))}
          suggestedMax={100}
          suggestedMin={0}
          calcType="average"
          displayType="percent"
          yAxisLabel="Response Rate"
          companyEvents={companyEvents}
        />
      </Container>
    </div>
  );
}
