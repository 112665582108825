import React from "react";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";

import Nav from "./pages/Nav/Nav";
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from "./contexts/authContext";

import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Registration from "./pages/Login/Registration";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Results from "./pages/Report/Results";
import Settings from "./pages/Settings/Settings";
import { CompanyProvider } from "./contexts/companyContext";
import ContactUs from "./pages/Support/ContactUs";
import { NotificationProvider } from "./contexts/notificationContext";
import NotificationsList from "./components/Notifications/NotificationList";
import MFA from "./pages/Login/MFA";
import Participants from "./pages/Participants/Users";
import Risk from "./pages/Risk/Risk";
import Compare from "./pages/Team/TeamOverview";
import Overview from "./pages/Overview/Overview";
import OverviewResults from "./pages/Overview/OverviewResults";
import { ConfirmationModalContextProvider } from "./contexts/confirmationModalContext";

export interface IContextProps {}

const App: React.FC<IContextProps> = (props) => {
  return (
    <div className="bg-background h-full">
      <Router>
        <NotificationProvider>
          <ConfirmationModalContextProvider>
            <AuthProvider>
              {/* User is signed in */}
              <AuthIsSignedIn>
                <CompanyProvider>
                  <Routes>
                    <Route path="" element={<Nav />}>
                      <Route path="home" element={<Home />} />
                      <Route path="accounts" element={<Overview />} />
                      <Route path="/*" element={<Navigate to={"/home"} />} />
                      <Route path="participants" element={<Participants />} />
                      <Route path="results" element={<Results />} />
                      <Route path="all" element={<OverviewResults />} />
                      <Route path="risk" element={<Risk />} />
                      <Route path="team" element={<Compare />} />
                      <Route path="assessments/:id" element={<Results />} />
                      <Route path="settings/*" element={<Settings />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="contact" element={<ContactUs />} />
                      <Route path="" element={<Navigate to={"home"} />} />
                    </Route>
                    <Route path="/login" element={<Navigate to={"/home"} />} />
                  </Routes>
                </CompanyProvider>
              </AuthIsSignedIn>

              {/* User is not signed in */}
              <AuthIsNotSignedIn>
                <Routes>
                  <Route path="/" element={<Navigate to={"/login"} />} />
                  <Route path="/*" element={<Navigate to={`/login?redirectUrl=${window.location.pathname}`} />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/mfa" element={<MFA />} />
                  <Route path="/registration" element={<Registration />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                </Routes>
              </AuthIsNotSignedIn>
            </AuthProvider>
          </ConfirmationModalContextProvider>
        </NotificationProvider>
      </Router>
    </div>
  );
};

export default App;
