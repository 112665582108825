import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import Button from "../../components/Button";
import useApi from "../../hooks/useApi";

export default function AddPaymentMethodForm(props: { onSucess: () => void, onCancel: () => void }) {
  const stripe = useStripe();
  const elements = useElements();
  const { postApiData } = useApi();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDefault, setIsDefault] = useState<boolean>(true);

  async function handleSubmit(event: any) {
    event.preventDefault();

    setSubmitting(true);

    if (!elements) {
      return;
    }

    const confirmationResult = await stripe!.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (confirmationResult.error) {
      setErrorMessage(confirmationResult.error.message);
      setSubmitting(false);
      return;
    }

    // set the new payment method as default
    if(isDefault) {
      await postApiData(`/company/payment/set-default-payment-method/${confirmationResult.setupIntent.payment_method}`, {});
    }

    props.onSucess();

    setSubmitting(false);
  }

  return (
    <form className="" onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          paymentMethodOrder: ["us_bank_account", "card"],
        }}
      />
      <div className="relative flex items-start  mt-3">
        <div className="flex h-6 items-center">
          <input
            id="isDefault"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
            checked={isDefault}
            onChange={(e) => setIsDefault(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm leading-6 mb-2">
          <label htmlFor="rememberDevice" className="font-medium text-gray-900">
            Set as default payment method
          </label>
        </div>
      </div>
      {errorMessage && <div className="mt-2 text-sm text-red-500">{errorMessage}</div>}
      <div className="mt-1 sm:flex sm:flex-row-reverse gap-2">
        <Button
          text={"Add New Payment Method"}
          type="submit"
          className="inline-flex w-full justify-center sm:col-start-2"
          disabled={!stripe}
          submitting={submitting}
        />
        <Button
          text="Cancel"
          type="button"
          data-autofocus
          variant="secondary"
          className="mt-3 inline-flex w-full justify-center sm:col-start-1 sm:mt-0"
          onClick={props.onCancel}
        />
      </div>
    </form>
  );
}
