import { Bar } from "react-chartjs-2";
import colors from "tailwindcss/colors";
import { BarElement, ChartData } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { useState } from "react";
import { PercentBadgeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import Toggle from "../../components/Toggle";
import customIcons from "../../assets/customIcons";

ChartJS.register(BarElement);

export default function GroupCategoryComparison(props: {
  groupCounts: {
    name: string;
    red: number;
    yellow: number;
    green: number;
    gray: number;
    taken: number;
  }[];
  printable?: boolean;
}) {
  const { groupCounts, printable } = props;

  const [calcType, setCalcType] = useState<"Count" | "Percentage">("Count");

  const colorRed = colors.red[300];
  const colorYellow = colors.yellow[300];
  const colorGreen = colors.green[400];
  const colorGray = "#D1D5DB";

  const chartLabels = groupCounts.length > 0 ? groupCounts.map((data: { name: string }) => data.name) : [];

  const data: ChartData<"bar", (number | null)[], string> = {
    labels: chartLabels,
    datasets: [
      {
        label: "",
        data:
          calcType === "Count"
            ? groupCounts.map((item) => (item.red > 0 ? item.red : null))
            : groupCounts.map((item) => item.red / (item.red + item.yellow + item.green + item.gray)), // This array is for all red values (value will follow index value of label.  Example red [0,1], groupName = [j, t] j=0 t=1)
        backgroundColor: [colorRed],
        stack: "Red", // confusing - stacks are bars
      },
      {
        label: "",
        data:
          calcType === "Count"
            ? groupCounts.map((item) => (item.yellow > 0 ? item.yellow : null))
            : groupCounts.map((item) => item.yellow / (item.red + item.yellow + item.green + item.gray)),
        backgroundColor: [colorYellow],
        stack: "Yellow",
      },
      {
        label: "",
        data:
          calcType === "Count"
            ? groupCounts.map((item) => (item.green > 0 ? item.green : null))
            : groupCounts.map((item) => item.green / (item.red + item.yellow + item.green + item.gray)),
        backgroundColor: [colorGreen],
        stack: "Green",
      },
      {
        label: "",
        data:
          calcType === "Count"
            ? groupCounts.map((item) => (item.gray > 0 ? item.gray : null))
            : groupCounts.map((item) => item.gray / (item.red + item.yellow + item.green + item.gray)),
        backgroundColor: [colorGray],
        stack: "Gray",
      },
    ],
  };

  return (
    <div className={`${printable ? "h-full" : "h-[85%]"} relative`}>
      {!printable && (
        <div className="w-full inline-flex  justify-end text-gray-500 hover:text-gray-700 cursor-pointer">
          <Toggle
            value={calcType === "Percentage"}
            setValue={(value) => (value ? setCalcType("Percentage") : setCalcType("Count"))}
            offIcon={customIcons.percentSign}
            onIcon={customIcons.user}
          />
        </div>
      )}
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top" as const,
              display: false,
            },
            datalabels: { display: false },
          },
          scales: {
            y: {
              title: {
                text: calcType === "Percentage" ? "Percent of Respondents" : "Number of Respondents",
                display: true,
              },
              ticks: {
                format: {
                  style: calcType === "Percentage" ? "percent" : undefined,
                },
                stepSize: calcType === "Percentage" ? 0.05 : 5,
                callback: function (value) {
                  if (calcType === "Percentage") {
                    //@ts-ignore
                    return value * 100 + "%"; // Round to integer and add the percentage symbol
                  } else {
                    return value;
                  }
                },
              },
              max: calcType === "Percentage" ? 1 : undefined,
            },
            x: {
              grid: {
                display: true,
              },
            },
          },
        }}
        data={data}
      />
    </div>
  );
}
