import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { loadStripe, PaymentMethod } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { useNotifications } from "../../contexts/notificationContext";
import Spinner from "../../components/Spinner";
import { Elements } from "@stripe/react-stripe-js";
import AddPaymentMethodForm from "./AddPaymentMethodForm";
import { useQueryClient } from "@tanstack/react-query";
import { useCompany } from "../../contexts/companyContext";

export default function PaymentMethodModal(props: { open: boolean; setOpen: (value: boolean) => void; paymentMethod: PaymentMethod | null }) {
  const { open, setOpen, paymentMethod } = props;
  const { postApiData } = useApi();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const { companyId } = useCompany();
  const [clientSecret, setClientSecret] = useState<string>();

  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!}`);

  async function createSetupIntent() {
    const { response, body } = await postApiData("/company/payment/create-setup-intent", {});

    if (response.ok) {
      setClientSecret(body.clientSecret);
    } else {
      notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
    }
  }

  useEffect(() => {
    if (open && !paymentMethod) {
      createSetupIntent();
    }
  }, [open, paymentMethod]);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="text-center sm:mt-1">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  Add New Payment Method
                </DialogTitle>
                <div className="mt-2">
                  {clientSecret ? (
                    <Elements
                      stripe={stripePromise}
                      options={{
                        clientSecret,
                        appearance: { theme: "stripe" },
                      }}
                    >
                      <AddPaymentMethodForm
                        onSucess={async () => {
                          notifications.addNotification("Success!", "The new payment method was saved successfully");
                          await queryClient.invalidateQueries({ queryKey: ["paymentMethods", companyId] });
                          setOpen(false);
                        }}
                        onCancel={() => setOpen(false)}
                      />
                    </Elements>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
