import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { createContext, useContext, useRef, useState } from "react";
import Button from "../components/Button";
import { ExclamationTriangleIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";

export enum ConfirmModalType {
  "info",
  "warning",
  "danger",
}

type ConfirmModalOptions = { title?: string; message?: string; type?: ConfirmModalType; confirmButtonText?: string; onConfirm?: () => Promise<void> };

type ModalContextType = {
  showConfirmation: (options?: ConfirmModalOptions) => Promise<boolean>;
};

const ConfirmationModalContext = createContext<ModalContextType>({} as ModalContextType);

export const ConfirmationModalContextProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  const [options, setOptions] = useState<ConfirmModalOptions>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const resolver = useRef<Function>();

  const modalContext: ModalContextType = {
    showConfirmation: (options?: ConfirmModalOptions): Promise<boolean> => {
      setShow(true);
      setOptions(options);
      return new Promise(function (resolve) {
        resolver.current = resolve;
      });
    },
  };

  // By passing an asyncronous onConfirm function, a spinner will show on the confirm button
  const handleConfirm = async () => {
    if (options?.onConfirm) {
      setSubmitting(true);
      await options.onConfirm();
      setSubmitting(false);
      setShow(false);
    } else {
      resolver.current && resolver.current(true);
      setShow(false);
    }
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShow(false);
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      <Dialog open={show} onClose={setShow} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="sm:flex sm:items-start">
                {(options?.type === ConfirmModalType.info || !options?.type) && (
                  <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:size-10">
                    <InformationCircleIcon aria-hidden="true" className="size-6 text-gray-600" />
                  </div>
                )}
                {options?.type === ConfirmModalType.warning && (
                  <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:size-10">
                    <ExclamationCircleIcon aria-hidden="true" className="size-6 text-yellow-500" />
                  </div>
                )}
                {options?.type === ConfirmModalType.danger && (
                  <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                    <ExclamationTriangleIcon aria-hidden="true" className="size-6 text-red-600" />
                  </div>
                )}
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                    {options?.title ?? "Confirmation"}
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{options?.message ?? "Are you sure you wish to proceed?"}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-2">
                <Button
                  text={options?.confirmButtonText ?? "Confirm"}
                  type="button"
                  onClick={handleConfirm}
                  className="inline-flex w-full justify-center sm:w-auto"
                  variant={options?.type === ConfirmModalType.danger ? "danger" : options?.type === ConfirmModalType.warning ? "warning" : undefined}
                  submitting={submitting}
                />
                <Button
                  text="Cancel"
                  type="button"
                  data-autofocus
                  onClick={handleCancel}
                  variant="secondary"
                  className="mt-3 inline-flex w-full justify-center sm:mt-0 sm:w-auto"
                />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </ConfirmationModalContext.Provider>
  );
};


// This hook works similar to the window.confirm method
// ex.
// const confirm = useConfirmationModal();
// async function doSomething() {
//   const confirmation = await confirm();
//   if(confirmation) {
//      do thing
//   }
// }
//
// or you can pass an asyncronous function to the onConfirm option to display a submitting spinner
// ex.
// await confirm({
//   title: "Delete Payment Method",
//   message: "Are you sure you want to delete this payment method?",
//   confirmButtonText: "Delete",
//   type: ConfirmModalType.danger,
//   onConfirm: async () => {
//     const { response } = await deleteApiData(`/company/payment/payment-method/${paymentMethodId}`);

//     if (response.ok) {
//       notifications.addNotification("Success!", "The payment method was deleted successfully");
//       await queryClient.invalidateQueries({ queryKey: ["paymentMethods", companyId] });
//     } else {
//       notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
//     }
//   },
// });
export const useConfirmationModal = () => useContext(ConfirmationModalContext).showConfirmation;
